import React, { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { useUserData, useConnectedManager } from 'state/user/hooks'
import { useNFTData } from 'state/nft/hooks'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { callMint, callMint2, callMint3 } from 'utils/calls/nft'
import { useWeb3 } from 'utils/useWeb3'
import { MAX_MINT_COUNT, GAS_LIMIT } from 'constants/config'
import { ActionArea, ActionButton, Card, CardHeader, ControlButton, ControlContent, CrossLine, DetailArea, DetailedCard, DetailTitle, DetailValue, Divider, ImageArea } from './styles'

const MintInfo = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`

const MintImage = styled.img`
  width: 150px;
  border-radius: 160px;
  border: 2px solid #c7a426;
`
const Level = styled.div`
  font-size: 26px;
  font-weight: 700;
  margin: 20px 15px;
  margin-bottom: 0;
`
interface DetailProps {
  title: string
  value: string
}

const MintCard: React.FC = () => {
  const web3 = useWeb3()
  const [count, setCount] = useState(1)
  const [totalGasLimit, SetTotalGasLimit] = useState('300000')
  const [connected] = useConnectedManager()

  const [userData] = useUserData()
  const [nftBasicData] = useNFTData()
  const { address, ethBalance, marsriseBalance } = userData
  const { mintPrice, mintPrice2, mintPrice3, totalSupply, totalSupply2, totalSupply3, data } = nftBasicData

  const maxCount = MAX_MINT_COUNT - data.length

  useEffect(() => {
    if (data.length === MAX_MINT_COUNT) {
      setCount(0)
    }
  }, [data])

  const handlePlus = () => {
    const mintCount = count + 1
    if (mintCount >= maxCount) {
      setCount(maxCount)
      SetTotalGasLimit(String(GAS_LIMIT * maxCount))
    } else {
      setCount(mintCount)
      SetTotalGasLimit(String(GAS_LIMIT * mintCount))
    }
  }

  const handleMinus = () => {
    const mintCount = count - 1
    if (mintCount < 1) {
      setCount(1)
    } else {
      setCount(mintCount)
    }
  }

  const handleMint = () => {
    if (address && web3) {
      callMint(web3, address, mintPrice, count, totalGasLimit)
    }
  }

  const handleMint2 = () => {
    if (address && web3) {
      callMint2(web3, address, mintPrice2, count, totalGasLimit)
    }
  }

  const handleMint3 = () => {
    if (address && web3) {
      callMint3(web3, address, mintPrice3, count, totalGasLimit)
    }
  }

  const Detail: React.FC<DetailProps> = ({ title, value }) => {
    return (
      <DetailedCard>
        <DetailTitle>{title}</DetailTitle>
        <DetailValue>{value}</DetailValue>
      </DetailedCard>
    )
  }

  const zeroBalance = !new BigNumber(marsriseBalance).gt(0)

  return (
    <Card>
      <CardHeader>Mint CROWITCHES</CardHeader>
      <MintInfo>
        <DetailArea>
          <Detail title="Gen1 Supply" value={`3,333 / ${totalSupply}`} />
          <Divider />
          <Detail title="Gen2 Supply" value={`3,000 / ${totalSupply2}`} />
          <Divider />
          <Detail title="Gen3 Supply" value={`2,000 / ${totalSupply3}`} />
          <Divider />
          <Detail title="Your CroWitches" value={connected ? `${data.length}` : '0'} />
        </DetailArea>
        <DetailArea>
          <Detail title="Gen1 Price" value={`${getFullDisplayBalance(new BigNumber(mintPrice), 18, 0)} CRO`} />
          <Divider />
          <Detail title="Gen2 Price" value={`${getFullDisplayBalance(new BigNumber(mintPrice2), 18, 0)} CRO`} />
          <Divider />
          <Detail title="Gen2 Price" value={`${getFullDisplayBalance(new BigNumber(mintPrice3), 18, 0)} CRO`} />
          <Divider />
          <Detail title="Your CRO Balance" value={connected ? `${getFullDisplayBalance(new BigNumber(ethBalance))} CRO` : '0 CRO'} />
        </DetailArea>
      </MintInfo>
      <ImageArea>
        <MintImage src="images/mint.gif" alt="mint" />
        <CrossLine />
      </ImageArea>
      <div>
        <ActionArea>
          <ControlButton onClick={handleMinus} disabled={count <= 1}>
            -
          </ControlButton>
          <ControlContent>{count}</ControlContent>
          <ControlButton onClick={handlePlus} disabled={count >= maxCount}>
            +
          </ControlButton>
        </ActionArea>
        <ActionArea>
        <ActionButton onClick={handleMint} disabled={!connected || count === 0 || maxCount === 0}>
          MINT Gen1
        </ActionButton>
        </ActionArea>
        <ActionButton onClick={handleMint2} disabled={!connected || count === 0 || maxCount === 0}>
          MINT Gen2
        </ActionButton>
        <ActionButton onClick={handleMint3} disabled={!connected || count === 0 || maxCount === 0}>
          MINT Gen3
        </ActionButton>
      </div>
      <MintInfo>
        {/*<DetailArea>
          <Detail title="Your CroWitches" value={connected ? `${data.length}` : '0'} />
        </DetailArea>
        <DetailArea>
          <Detail title="Your CRO Balance" value={connected ? `${getFullDisplayBalance(new BigNumber(ethBalance))} CRO` : '0 CRO'} />
        </DetailArea> */}
      </MintInfo>
    </Card>
  )
}

export default MintCard
