import { createReducer } from '@reduxjs/toolkit'
import {
  updateFetching,
  updateAddress,
  updateMarsRiseBalance,
  updateEthBalance,
  updateNFTAllowance,
  updateLotteryAllowance,
  updateConnected,
} from './actions'

export interface UserState {
  fetching: boolean;
  connected: boolean;
  address: string;
  ethBalance: string;
  marsriseBalance: string;
  nftAllowance: string;
  lotteryAllowance: string;
}

export const initialState: UserState = {
  fetching: false,
  connected: false,
  address: "",
  ethBalance: "",
  marsriseBalance: "",
  nftAllowance: "0",
  lotteryAllowance: "0",
}

export default createReducer(initialState, (builder) => {
  builder
  .addCase(updateFetching, (state, { payload: { fetching } }) => {
    state.fetching = fetching
  })
  .addCase(updateConnected, (state, { payload: { connected } }) => {
    state.connected = connected
  })
  .addCase(updateAddress, (state, { payload: { address } }) => {
    state.address = address
  })
  .addCase(updateEthBalance, (state, { payload: { ethBalance } }) => {
    state.ethBalance = ethBalance
  })
  .addCase(updateMarsRiseBalance, (state, { payload: { marsriseBalance } }) => {
    state.marsriseBalance = marsriseBalance
  })
  .addCase(updateNFTAllowance, (state, { payload: { nftAllowance } }) => {
    state.nftAllowance = nftAllowance
  })
  .addCase(updateLotteryAllowance, (state, { payload: { lotteryAllowance } }) => {
    state.lotteryAllowance = lotteryAllowance
  })
})