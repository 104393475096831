import styled from 'styled-components'
import Button from 'components/Button'

export const Card = styled.div`
  border: 2px solid #c7a426;
  border-radius: 6px;
  background: #00000080;
  min-height: 520px;

  @media screen and (min-width: 310px) {
    width: 100%;
    max-width: 275px;
    min-height: 540px;
  }
  @media screen and (min-width: 375px) {
    width: auto;
    max-width: none;
  }
`

export const CardHeader = styled.div`
  font-size: 24px;
  line-height: 60px;
  border-bottom: 2px solid #c7a426;
`

export const DetailArea = styled.div`
  background: #ffffff20;
  border-radius: 6px;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #ffffff60;
`

export const DetailedCard = styled.div`
  padding: 10px;
`

export const DetailTitle = styled.div`
  opacity: 0.6;
  font-size: 12px;
`

export const DetailValue = styled.div`
  font-size: 14px;
`

export const ImageArea = styled.div`
  margin: 20px 0;
`

export const CrossLine = styled.div`
  width: 100%;
  height: 2px;
  background: #c7a426;
  margin-top: -80px;
  margin-bottom: 75px;
`

export const ActionArea = styled.div`
  margin: 10px 0px;
  padding-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const ControlContent = styled.div`
  font-size: 26px;
  font-weight: 700;
  margin: 0 15px;
`

export const ControlButton = styled.button<{ disable?: boolean }>`
  background: transparent;
  outline: none;
  color: ${({ disabled }) => (disabled ? '#FFFFFF80' : 'white')};
  font-size: 24px;
  font-weight: 700;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const ActionButton = styled(Button)`
  min-width: 130px;
  max-width: 130px;
  height: 36px;
  border-radius: 100px;
  margin-left: 5px;
`
export const LotteryButton = styled(Button)`
  min-width: 210px;
  height: 36px;
  border-radius: 100px;
  margin-top: 10px;
`

export const ActionSecondaryButton = styled(Button)`
  min-width: 130px;
  max-width: 130px;
  height: 36px;
  border-radius: 100px;
  background: transparent;
  border: 2px solid #c7a426;
  margin: 10px 0;
`

export const Descriptions = styled.div`
  min-height: 60px;
  justify-content: left;
`

export const Description = styled.div`
  text-align: left;
  font-size: 14px;
  margin: 0 10px;
  color: #ffffff80;
`
