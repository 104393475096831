import { useEffect } from 'react'
import { useAppDispatch } from 'state'
import { updateMintPrice, updateMintPrice2, updateMintPrice3, updateTotalSupply, updateTotalSupply2, updateTotalSupply3, updateBoostPrice, updateData, updateAddNFTData } from './actions'
import { callMintPrice, callTotalSupply, callMintPrice2, callTotalSupply2, callMintPrice3, callTotalSupply3, callBalanceOf, callTokenOfOwnerByIndex, callSpaceManInfoList, callBoostPrice } from 'utils/calls/nft'
import { useSlowFresh, useFastFresh } from 'hooks/useRefresh'
import { useWeb3 } from 'utils/useWeb3'
import { useEthBalance } from '../user/fetch'

export const useMintPrice = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const slowFresh = useSlowFresh()
  useEffect(() => {
    const getNFTMintPrice = async() => {
      const result = await callMintPrice(web3);
      if (result) {
        dispatch(updateMintPrice({ mintPrice: `${result}` }))
      }
    }
    if (web3) {
      getNFTMintPrice()
    }
  }, [web3, address, dispatch, slowFresh])
}

export const useTotalSupply = (web3: any) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getNFTTotalSupply = async() => {
      const result = await callTotalSupply(web3)
      if (result) {
        dispatch(updateTotalSupply({ totalSupply: Number(result) }))
      }
    }
    if (web3) {
      getNFTTotalSupply()
    }
  }, [web3, dispatch, fastRefresh])
}

export const useMintPrice2 = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const slowFresh = useSlowFresh()
  useEffect(() => {
    const getNFTMintPrice2 = async() => {
      const result = await callMintPrice2(web3);
      if (result) {
        dispatch(updateMintPrice2({ mintPrice: `${result}` }))
      }
    }
    if (web3) {
      getNFTMintPrice2()
    }
  }, [web3, address, dispatch, slowFresh])
}

export const useTotalSupply2 = (web3: any) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getNFTTotalSupply2 = async() => {
      const result = await callTotalSupply2(web3)
      if (result) {
        dispatch(updateTotalSupply2({ totalSupply: Number(result) }))
      }
    }
    if (web3) {
      getNFTTotalSupply2()
    }
  }, [web3, dispatch, fastRefresh])
}
export const useMintPrice3 = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const slowFresh = useSlowFresh()
  useEffect(() => {
    const getNFTMintPrice3 = async() => {
      const result = await callMintPrice3(web3);
      if (result) {
        dispatch(updateMintPrice3({ mintPrice: `${result}` }))
      }
    }
    if (web3) {
      getNFTMintPrice3()
    }
  }, [web3, address, dispatch, slowFresh])
}

export const useTotalSupply3 = (web3: any) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getNFTTotalSupply3 = async() => {
      const result = await callTotalSupply3(web3)
      if (result) {
        dispatch(updateTotalSupply3({ totalSupply: Number(result) }))
      }
    }
    if (web3) {
      getNFTTotalSupply3()
    }
  }, [web3, dispatch, fastRefresh])
}
export const useBoostPrice = (web3: any) => {
  const dispatch = useAppDispatch()
  const slowFresh = useSlowFresh()
  useEffect(() => {
    const getBoostPrice = async() => {
      const result = await callBoostPrice(web3);
      if (result) {
        dispatch(updateBoostPrice({ boostPrice: `${result}` }))
      }
    }
    if (web3) {
      getBoostPrice()
    }
  }, [web3, dispatch, slowFresh])
}

export const useNFTUserDatas = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const slowFresh = useSlowFresh()
  useEffect(() => {
    const getNFTData = async(index: number) => {
      const tokenId = await callTokenOfOwnerByIndex(web3, address, index)
      const tokenInfo = await callSpaceManInfoList(web3, Number(tokenId))
      const info = JSON.parse(JSON.stringify(tokenInfo));
      
      const nft = {
        id: Number(tokenId),
        boostLevel: `${info.boostLevel}`,
        nextBoostTime: `${info.nextBoostTime}`,
      }
      dispatch(updateAddNFTData({data: nft}))
    }

    const getNFTBalance = async() => {
      const result = await callBalanceOf(web3, address)
      const balance = Number(result)

      if (web3 && address && balance) {
        for (let i = 0; i < balance; i ++) {
          getNFTData(i)
        }
      }
    }

    if (web3 && address) {
      getNFTBalance()
    }
  }, [web3, address, dispatch, slowFresh])
}

export const useFetchNFTData = (address: string) => {
  const web3 = useWeb3()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updateData({data: []}))
  }, [address, dispatch])

  useMintPrice(web3, address)
  useTotalSupply(web3)
  useMintPrice2(web3, address)
  useTotalSupply2(web3)
  useMintPrice3(web3, address)
  useTotalSupply3(web3)
  useNFTUserDatas(web3, address)
  useBoostPrice(web3)  
  useEthBalance(web3, address)
}