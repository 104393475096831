import { MaxUint256 } from '@ethersproject/constants'

export function callBalanceOf(contract: any, address: string, web3: any) {
  return new Promise(async (resolve, reject) => {
    await contract.methods.balanceOf(address).call({ from: '0x0000000000000000000000000000000000000000' }, (err: any, data: any) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })
}

export function callTransfer(contract: any, address: string, web3: any) {
  return new Promise(async (resolve, reject) => {
    await contract.methods.transfer(address, '1').send({ from: address }, (err: any, data: any) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })
}

export function callApprove(contract: any, spender: string, address: string, web3: any) {
  return new Promise(async (resolve, reject) => {
    await contract.methods.approve(spender, MaxUint256).send({ from: address }, (err: any, data: any) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })
}

export function callAllowance(contract: any, spender: string, owner: string, web3: any) {
  return new Promise(async (resolve, reject) => {
    await contract.methods.allowance(owner, spender).call({ from: '0x0000000000000000000000000000000000000000' }, (err: any, data: any) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })
}
