import { useEffect } from 'react'
import { useAppDispatch } from 'state'
import { updateEthBalance, updateMarsRiseBalance, updateNFTAllowance, updateLotteryAllowance } from './actions'
import { useFastFresh } from 'hooks/useRefresh'
import { callBalanceOf, callAllowance } from 'utils/calls/token'
import { getNFTAddress, getLotteryAddress } from 'utils/addressHelper'
import { getMarsRiseContract } from 'utils/contractHelper'
import { useWeb3 } from 'utils/useWeb3'

export const useEthBalance = (web3: any | undefined, address: string | undefined) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getETHBalance = async () => {
      const result = await web3.eth.getBalance(address)
      dispatch(updateEthBalance({ ethBalance: result }))
    }
    if (web3 && address) {
      getETHBalance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useMarsRiseBalance = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getMarsRiseBalance = async () => {
      const result = await callBalanceOf(getMarsRiseContract(web3), address, web3)
      if (result) {
        dispatch(updateMarsRiseBalance({ marsriseBalance: `${result}` }))
      }
    }
    if (web3 && address) {
      getMarsRiseBalance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useNFTAllowance = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getNFTAllowance = async () => {
      const result = await callAllowance(getMarsRiseContract(web3), getNFTAddress(), address, web3)
      if (result) {
        dispatch(updateNFTAllowance({ nftAllowance: `${result}` }))
      }
    }
    if (web3 && address) {
      getNFTAllowance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useLotteryAllowance = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getLotteryAllowance = async () => {
      const result = await callAllowance(getMarsRiseContract(web3), getLotteryAddress(), address, web3)
      if (result) {
        dispatch(updateLotteryAllowance({ lotteryAllowance: `${result}` }))
      }
    }
    if (web3 && address) {
      getLotteryAllowance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useFetchUserData = (address: string) => {
  const web3 = useWeb3()
  useEthBalance(web3, address)
  useMarsRiseBalance(web3, address)
  useNFTAllowance(web3, address)
  useLotteryAllowance(web3, address)
}
