import BigNumber from 'bignumber.js'
import { parseEther } from '@ethersproject/units'
import { BIG_TEN } from 'utils/bigNumber'
import { convertStringToNumber } from 'helpers/bignumber'
import { getNFTContract, getNFTContract2, getNFTContract3 } from '../contractHelper'

export function callMintPrice(web3: any) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .mintPrice()
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callTotalSupply(web3: any) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .totalSupply()
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callMintPrice2(web3: any) {
  const nftContract2 = getNFTContract2(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract2.methods
      .mintPrice()
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callTotalSupply2(web3: any) {
  const nftContract2 = getNFTContract2(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract2.methods
      .totalSupply()
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callMintPrice3(web3: any) {
  const nftContract3 = getNFTContract3(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract3.methods
      .mintPrice()
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callTotalSupply3(web3: any) {
  const nftContract3 = getNFTContract3(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract3.methods
      .totalSupply()
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callBalanceOf(web3: any, address: string) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .balanceOf(address)
      .call({ from: '0x0000000000000000000000000000000000000000' }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callBoostPrice(web3: any) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .boostPrice()
      .call({ from: '0x0000000000000000000000000000000000000000' }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callTokenOfOwnerByIndex(web3: any, address: string, index: number) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .tokenOfOwnerByIndex(address, index.toString())
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callSpaceManInfoList(web3: any, index: number) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .witchInfoList(index.toString())
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callMint(web3: any, address: string, price: string, count: number, gaslimit: string) {
  const nftContract = getNFTContract(web3)
  const cost = convertStringToNumber(price) * count

  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .mintWitch(count.toString())
      .send({ gasLimit: gaslimit, from: address, value: new BigNumber(cost) }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callMint2(web3: any, address: string, price: string, count: number, gaslimit: string) {
  const nftContract2 = getNFTContract2(web3)
  const cost = convertStringToNumber(price) * count

  return new Promise(async(resolve, reject) => {
    await nftContract2.methods
      .mintWitch(count.toString())
      .send({ gasLimit: gaslimit, from: address, value: new BigNumber(cost) }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callMint3(web3: any, address: string, price: string, count: number, gaslimit: string) {
  const nftContract3 = getNFTContract3(web3)
  const cost = convertStringToNumber(price) * count

  return new Promise(async(resolve, reject) => {
    await nftContract3.methods
      .mintWitch(count.toString())
      .send({ gasLimit: gaslimit, from: address, value: new BigNumber(cost) }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callBoostSpaceMan(web3: any, address: string, id: number) {
  const nftContract = getNFTContract(web3)  
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .getMagicPower(id.toString())
      .send({ from: address }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callSellBoostSpaceMan(web3: any, address: string, id: number) {
  const nftContract = getNFTContract(web3)  
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .sellMagicLevel(id.toString())
      .send({ from: address }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}