import { createAction } from '@reduxjs/toolkit'
import { UserInfo } from '.'

interface JoinData {
  type: number
  roundNumber: string
  roundPrice: string
  userAmount: string
}

interface UserData extends UserInfo {
  type: number
}

export const updateFetching = createAction<{ fetching: boolean }>('lottery/updateFetching')
export const updateJoinData = createAction<{ joinData: JoinData }>('lottery/updateJoinData')
export const updateUserData = createAction<{ userData: UserData }>('lottery/updateUserData')
