import { useEffect } from 'react'
import { useAppDispatch } from 'state'
import { updateJoinData, updateUserData } from './actions'
import { callJoinInfo, callUserInfo } from 'utils/calls/lottery'
import { useSlowFresh } from 'hooks/useRefresh'
import { useWeb3 } from 'utils/useWeb3'

export const useFetchRoundData = (type: number) => {
  const web3 = useWeb3()
  const dispatch = useAppDispatch()
  const slowFresh = useSlowFresh()

  useEffect(() => {
    const getLotteryJoinData = async () => {
      const result: any = await callJoinInfo(web3, type)
      if (result) {
        const data = {
          type,
          roundNumber: result.roundNumber,
          roundPrice: result.roundPrice,
          userAmount: result.userAmount,
        }
        dispatch(updateJoinData({ joinData: data }))
        if (result.roundNumber > 0) {
          const userInfo: any = await callUserInfo(web3, type, Number(result.roundNumber) - 1)
          if (userInfo) {
            const winner = {
              type,
              firstWinner: userInfo.firstWinner,
              secondWinner: userInfo.secondWinner,
            }
            dispatch(updateUserData({ userData: winner }))
          }
        }
      }
    }
    if (web3) {
      getLotteryJoinData()
    }
  }, [type, dispatch, slowFresh])
}

export const useFetchLotteryData = (type: number) => {
  useFetchRoundData(type)
}
