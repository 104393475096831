import * as React from "react";
import styled from "styled-components";
import Web3 from "web3";
// @ts-ignore
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnect from "@walletconnect/web3-provider";
// @ts-ignore
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
// @ts-ignore
import Torus from "@toruslabs/torus-embed";

import Column from "./components/Column";
import Header from "./components/Header";
import { getChainData } from "./helpers/utilities";
import { INFURA_ID, RPC_URL } from "constants/config"

import Home from "views/Home"

const Background = styled.div`
  background: url(/images/background.png);
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
`

const SLayout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  background: #00000080;
`;

const BlackBackground = styled.div`
  background: #00000010;
  height: 100vh;
  position: fixed;
`

const MarkArea = styled.div`
  color: white;
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
`

interface IAppState {
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
}

const INITIAL_STATE: IAppState = {
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: 25,
  networkId: 25,
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });

  return web3;
}

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };

    this.web3Modal = new Web3Modal({
      network: this.getNetwork(),
      cacheProvider: true,
      providerOptions: this.getProviderOptions()
    });
  }

  public componentDidMount() {
    if (this.web3Modal.cachedProvider) {
      this.onConnect();
    }
  }

  public onConnect = async () => {
    const provider = await this.web3Modal.connect();

    await this.subscribeProvider(provider);

    await provider.enable();
    const web3: any = initWeb3(provider);

    const accounts = await web3.eth.getAccounts();

    const address = accounts[0];

    const networkId = await web3.eth.net.getId();

    const chainId = await web3.eth.chainId();

    await this.setState({
      web3,
      provider,
      connected: true,
      address,
      chainId,
      networkId
    });
  };

  public subscribeProvider = async (provider: any) => {
    provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts: string[]) => {
      await this.setState({ address: accounts[0] });
    });
    provider.on("chainChanged", async (chainId: number) => {
      const { web3 } = this.state;
      const networkId = await web3.eth.net.getId();
      await this.setState({ chainId, networkId });
    });

    provider.on("networkChanged", async (networkId: number) => {
      const { web3 } = this.state;
      const chainId = await web3.eth.chainId();
      await this.setState({ chainId, networkId });
    });
  };

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnect,
        options: {
          rpc: {
              [25]: RPC_URL,
          },
        }
      }
    };
    return providerOptions;
  };

  public resetApp = async () => {
    const { web3 } = this.state;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await this.web3Modal.clearCachedProvider();
    this.setState({ ...INITIAL_STATE });
  };

  public render = () => {
    const {
      web3,
      address,
      connected,
      chainId,
    } = this.state;
    return (
      <SLayout>
        <Background/>
        <BlackBackground />
        <Column maxWidth={1280} spanHeight>
          <Header
            connected={connected}
            address={address}
            chainId={chainId}
            killSession={this.resetApp}
            walletConnect={this.onConnect}
          />
          <Home web3={web3} address={address} chainId={chainId} />
        </Column>
        <MarkArea>
          <div>3,333 CROWITCHES</div>
          <div>© 2022 CroWitches</div>
        </MarkArea>
      </SLayout>
    );
  };
}

export default App;
