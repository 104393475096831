import BigNumber from 'bignumber.js'
import { convertStringToNumber } from 'helpers/bignumber'
import { getLotteryContract } from '../contractHelper'

export function callJoinInfo(web3: any, type: number) {
  const lotteryContract = getLotteryContract(web3)
  return new Promise(async (resolve, reject) => {
    await lotteryContract.methods.joinInfos(type).call((err: any, data: any) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })
}

export function callUserInfo(web3: any, type: number, round: number) {
  const lotteryContract = getLotteryContract(web3)
  return new Promise(async (resolve, reject) => {
    await lotteryContract.methods.userInfos(type, round).call((err: any, data: any) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })
}

export function callUserFromIndex(web3: any, type: number, round: number, index: number) {
  const lotteryContract = getLotteryContract(web3)
  return new Promise(async (resolve, reject) => {
    await lotteryContract.methods.getUserFromIndex(type, round, index).call((err: any, data: any) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })
}

export function callJoinLottery(web3: any, address: string, type: number) {
  const lotteryContract = getLotteryContract(web3)
  return new Promise(async (resolve, reject) => {
    await lotteryContract.methods.joinLottery(type).send({ from: address }, (err: any, data: any) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })
}
