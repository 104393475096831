export interface ContractSetup {
  address: string;
  abi: any[];
}

export const DAI_CONTRACT: { [chainId: number]: ContractSetup } = {
  1: {
    address: "0x6b175474e89094c44da98b954eedeac495271d0f",
    abi: [
      {
        constant: true,
        inputs: [{ name: "src", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "dst", type: "address" },
          { name: "wad", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
  },
};

const contracts = {
  marsrise: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x30272ca83C2A8EeE5b461904Fb0391f3dCdAEABA",
    97: "0xAe5d55541d2987483bBe5Bb5de47AfB06B97B304",
    56: "0x184079Ca987F562ae6a0c59f4BE5cADB20323863",
    25: "0x5Eb71485f0736d368ddC5f290ac217d2A877fCf9",
  },
  nft: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x418D7288086Fd4D3950b691B6e63b4F7b0e9330D",
    97: "0xd6bf8feedd8e3eb0499b25caba720dc5a7a09410",
    56: "0xA27FD567a7dBC7ADFE712Cf1E3833F2b0E69BA5C",
    25: "0x95603A09FE2D2aFDD7CE5d0E3cD83319666E62d9",
  },
  nftgen2: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x418D7288086Fd4D3950b691B6e63b4F7b0e9330D",
    97: "0xd6bf8feedd8e3eb0499b25caba720dc5a7a09410",
    56: "0xA27FD567a7dBC7ADFE712Cf1E3833F2b0E69BA5C",
    25: "0x5ac04d6f18386ad2b556d16c2cB99F0d9f7564CB",
  },
  nftgen3: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x418D7288086Fd4D3950b691B6e63b4F7b0e9330D",
    97: "0xd6bf8feedd8e3eb0499b25caba720dc5a7a09410",
    56: "0xA27FD567a7dBC7ADFE712Cf1E3833F2b0E69BA5C",
    25: "0xfBf2727Bef4F6C4749948bed4aB865c448470898",
  },
  lottery: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    4: "0x418D7288086Fd4D3950b691B6e63b4F7b0e9330D",
    97: "0xd6bf8feedd8e3eb0499b25caba720dc5a7a09410",
    56: "0xA27FD567a7dBC7ADFE712Cf1E3833F2b0E69BA5C",
    25: "0x13f4792DEBA0011b16566e8cbB6497382741675e",
  }
}

export default contracts