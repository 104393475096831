import React, { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { useUserData, useLotteryAllowanceManager, useConnectedManager } from 'state/user/hooks'
import { useFetchLotteryData } from 'state/lottery/fetch'
import { useLotteryData } from 'state/lottery/hooks'
import { callUserFromIndex, callJoinLottery } from 'utils/calls/lottery'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { useWeb3 } from 'utils/useWeb3'
import { callApprove } from 'utils/calls/token'
import { getMarsRiseContract } from 'utils/contractHelper'
import { getLotteryAddress } from 'utils/addressHelper'
import { ellipseAddress } from 'helpers/utilities'
import { LotteryButton, Card, CardHeader, DetailArea, DetailedCard, DetailTitle, DetailValue, Divider } from './styles'

const DetailInfoArea = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`

const Level = styled.div`
  font-size: 15px;
  font-weight: 700;
  margin: 20px 15px;
  margin-bottom: 0;
`

const Pool = styled.div`
  font-size: 15px;
  font-weight: 700;
  margin: 20px 15px;
  margin-bottom: 0;
`

const LotteryButtons = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 20px;
`

const Tabs = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 10px;
  justify-content: space-around;
  border-bottom: 1px solid #fff;
  margin-top: 20px;
`

const Tab = styled.div<{ active?: boolean }>`
  color: ${({ active }) => (active ? '#fff' : '#ffffff80')};
  cursor: pointer;
  background: #ffffff20;
  padding: 5px 20px;
  font-size: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

const StyledExternalLink = styled.a`
  margin-top: 0px;
`

interface DetailProps {
  title: string
  value: string
}

const Detail: React.FC<DetailProps> = ({ title, value }) => {
  return (
    <DetailedCard>
      <DetailTitle>{title}</DetailTitle>
      <DetailValue>{value}</DetailValue>
    </DetailedCard>
  )
}

const RoundInfo: React.FC<{ type: number }> = ({ type }) => {
  const web3 = useWeb3()
  const [userData] = useUserData()
  const [firstWinner, setFirstWinner] = useState('0x0000000000000000000000000000000000000000')
  const [secondWinner, setSecondWinner] = useState('0x0000000000000000000000000000000000000000')
  const [lotteryAllowance, setLottoryAllowance] = useLotteryAllowanceManager()
  const [connected] = useConnectedManager()

  useFetchLotteryData(type)
  const lotteryData = useLotteryData()
  const roundData = lotteryData.joinData[type]
  const winnerData = lotteryData.userData[type]

  // console.log('pooh, winnerData = ', winnerData)

  const { address, marsriseBalance } = userData
  const isAllowed = new BigNumber(lotteryAllowance).gt(0)

  // console.log('pooh, marsriseBalance = ', marsriseBalance)
  // console.log('pooh, roundData.roundPrice = ', roundData.roundPrice)

  const enoughBalance = new BigNumber(marsriseBalance).gte(new BigNumber(roundData.roundPrice))

  const handleEnable = async () => {
    if (address && web3) {
      const result = await callApprove(getMarsRiseContract(web3), getLotteryAddress(), address, web3)
      setLottoryAllowance(parseInt(`${result}`, 16).toString())
    }
  }

  const handleJoin = (typeIndex: number) => {
    console.log('pooh, typeIndex = ', typeIndex)
    callJoinLottery(web3, address, typeIndex)
  }

  useEffect(() => {
    const getLotteryJoinData = async (index: number) => {
      if (Number(roundData.roundNumber) === 0) {
        if (index === 0) {
          setFirstWinner('0x0000000000000000000000000000000000000000')
        } else {
          setSecondWinner('0x0000000000000000000000000000000000000000')
        }
      }
      if (Number(roundData.roundNumber) >= 1) {
        const result: any = await callUserFromIndex(web3, type, Number(roundData.roundNumber) - 1, Number(index === 0 ? winnerData.firstWinner : winnerData.secondWinner))
        if (result) {
          if (index === 0) {
            setFirstWinner(result)
          } else {
            setSecondWinner(result)
          }
        }
      }
    }

    if (roundData && winnerData) {
      getLotteryJoinData(0)
      getLotteryJoinData(1)
    }
  }, [type, roundData, winnerData])

  return (
    <>
      <DetailInfoArea>
        <DetailArea>
          <Detail title="Round" value={(Number(roundData.roundNumber) + 1).toString()} />
          <Divider />
          <Detail title="Joined User" value={roundData.userAmount} />
        </DetailArea>
        <DetailArea>
          <Detail title="Last 1st Winners" value={ellipseAddress(firstWinner, 4)} />
          <Divider />
          <Detail title="Last 2nd Winners" value={ellipseAddress(secondWinner, 4)} />
        </DetailArea>
      </DetailInfoArea>
      <Level>Wait for the 7th user to join in the round you choose.</Level>
      <div>
        <LotteryButtons>
          {!isAllowed ? (
            <LotteryButton onClick={handleEnable} disabled={!connected}>
              Enable Lottery{' '}
            </LotteryButton>
          ) : (
            <Pool>
              <StyledExternalLink href="https://mm.finance/swap?outputCurrency=0x5eb71485f0736d368ddc5f290ac217d2a877fcf9" target="_blank" rel="noreferrer">
                <LotteryButton>Buy $CROSPELL</LotteryButton>
              </StyledExternalLink>
              <LotteryButton onClick={() => handleJoin(type)} disabled={!connected || !enoughBalance}>
                {`Join ${getFullDisplayBalance(new BigNumber(roundData.roundPrice), 9, 0)} $CROSPELL`}
              </LotteryButton>
            </Pool>
          )}
        </LotteryButtons>
      </div>
    </>
  )
}

const LotteryCard: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <Card>
      <CardHeader>Smart Lottery</CardHeader>
      <DetailInfoArea>
        <DetailArea>
          <Detail title="1st Winner's Profit" value={`400%`} />
        </DetailArea>
        <DetailArea>
          <Detail title="2nd Winner's Profit" value={`200%`} />
        </DetailArea>
      </DetailInfoArea>
      <Tabs>
        <Tab
          active={selectedTab === 0}
          onClick={() => {
            setSelectedTab(0)
          }}
        >
          Ritual
        </Tab>
        <Tab
          active={selectedTab === 1}
          onClick={() => {
            setSelectedTab(1)
          }}
        >
          Curse
        </Tab>
        <Tab
          active={selectedTab === 2}
          onClick={() => {
            setSelectedTab(2)
          }}
        >
          Layer
        </Tab>
      </Tabs>
      <RoundInfo type={selectedTab} />
    </Card>
  )
}

export default LotteryCard
