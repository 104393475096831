import React, { useEffect } from "react"
import styled from "styled-components"
import ConnectButton from "./ConnectButton"
import { useConnectedManager } from "state/user/hooks"
import { ellipseAddress } from "../helpers/utilities"

const SHeader = styled.div`
  margin-top: -1px;
  margin-bottom: 1px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const LogoImage = styled.div`
  @media screen and (min-width: 310px){
    background-image: url(images/logo.png);
    width: 50px;
    height: 20px;
  }
  @media screen and (min-width: 576px){
    background-image: url(images/logoWithText.png);
    width: 300px;
    height: 100px;
  }
  background-repeat: no-repeat;
`

interface IHeaderProps {
  killSession: () => void;
  connected: boolean;
  address: string;
  chainId: number;
  walletConnect: () => void;
}

const Header: React.FC<IHeaderProps> = ({connected: connectStatus, address, killSession, walletConnect}) => {
  const [connected, setConnected] = useConnectedManager()

  useEffect(() => {
    if (connectStatus !== connected) {
      setConnected(connectStatus)
    }
  }, [connected, setConnected, connectStatus])
  
  return (
    <SHeader>
      <a href="https://crowitches.com" target="_blank" rel="noreferrer">
        <LogoImage />
      </a>
      {connected && address && (
        <ConnectButton onClick={killSession} label={`Disconnect (${ellipseAddress(address)})`} />
      )}
      {!address && walletConnect &&        
        <ConnectButton onClick={walletConnect} />
      }
    </SHeader>
  );
};

export default Header;
